import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

// main pages
import Home from "./pages/home";
import Team from "./pages/team";
import Projects from "./pages/projects";
import Events from "./pages/events_page";
import DRC from "./pages/drc";
import SponsorsPage from "./pages/sponsors_page";
import ContactForm from "./pages/contact";
import Resources from "./pages/resources/resources";
import ThankYou from "./pages/thank_you"

// developer page
import Developers from "./pages/developers";

// project page and data
import ProjectDetails from "./pages/project_details";

// error pages
import NotFound from "./pages/404";

// TODO: Add these back somehow (drc_wrap_up and inaugural_drc)
// DRC (hardcoded resources)
import TeamsPortal from "./pages/drc/teams_portal";
import AutonomousVehicleGuide from "./pages/drc/autonomous_vehicle_guide";
import MathWorksResources from "./pages/drc/mathworks_resources";
import DrcWrapUp from "./pages/drc/drc_wrap_up";
import InauguralDrc from "./pages/drc/inaugural_drc";

import Faqs from "./pages/drc/faqs";
import Rules from "./pages/drc/rules";
import PrizeRules from "./pages/drc/prize_rules";
import Map from "./pages/drc/map";
import CompetitionFormat from "./pages/drc/competition_format";

// workshops (hardcoded resources)
import RobotArmWorkshop from "./pages/resources/workshops/robotic_workshop";
import WorkBots from "./pages/resources/workshops/workbot";
import Suppliers from "./pages/resources/workshops/suppliers";

import RoboticsSystems from "./pages/resources/workshops/robotics_systems";
import Soldering from "./pages/resources/workshops/soldering";
import ModellingWithCad from "./pages/resources/workshops/modelling_with_cad";
import RobotPowerSystems from "./pages/resources/workshops/robot_power_systems";
import ProgrammingMicrocontrollers from "./pages/resources/workshops/programming_microcontrollers";

// Previous DRC competitions
import TwentySixteen from "./pages/drc/previous_competitions/TwentySixteen";
import TwentySeventeen from "./pages/drc/previous_competitions/TwentySeventeen";
import TwentyEighteen from "./pages/drc/previous_competitions/TwentyEighteen";
import TwentyNineteen from "./pages/drc/previous_competitions/TwentyNineteen";
import TwentyTwenty from "./pages/drc/previous_competitions/TwentyTwenty";
import TwentyTwentyone from "./pages/drc/previous_competitions/TwentyTwentyone";
import TwentyTwentytwo from "./pages/drc/previous_competitions/TwentyTwentytwo";
import TwentyTwentyThree from "./pages/drc/previous_competitions/TwentyTwentyThree";
import TwentyTwentyFour from "./pages/drc/previous_competitions/TwentyTwentyFour";


// components
import Header from "./components/navbar";
import Footer from "./components/footer";
import ScrollToTop from "./components/scroll_to_top";
import ReactGA from "react-ga4";

// data
import {getProjects} from "./db/data.js";

const TRACKING_ID = 'G-PMQVX044MP';

function App() {
  // Initialize Google Analytics with your tracking ID
  if (window.location.hostname !== 'localhost') {
    ReactGA.initialize(TRACKING_ID, { testMode: process.NODE_ENV === 'test'});
  }
  const location = useLocation();

    useEffect(() => {
      if (window.location.hostname !== 'localhost') {
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname});
      }
    }, [location]);

  const [projectsData, setProjectsData] = useState([])

  useEffect(() => {
    getProjects()
        .then(projects => {
          setProjectsData(projects)
        })
  }, [])

  if (projectsData.length === 0) return <></>

  return (
    <div className="App">
        <style>{"body { background-color: #fff; }"}</style>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/team" element={<Team />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/events" element={<Events />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/sponsors" element={<SponsorsPage />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="/thank_you" element={<ThankYou />} />
          {projectsData.map((project, index) => (
            <Route
              key={index}
              path={`/projects/${project.slug}`}
              element={<ProjectDetails project={project} />}
            />
          ))}

          {/* DRC pages */}
          <Route path="drc">
            <Route index element={<DRC />} />

            <Route path="teams-portal" element={<TeamsPortal />} />
            <Route path="faqs" element={<Faqs />} />
            <Route path="rules" element={<Rules />} />
            <Route path="autonomous-vehicle-guide" element={<AutonomousVehicleGuide />} />
            <Route path="mathworks-resources" element={<MathWorksResources />} />
            <Route path="matlab-adoption-prize-rules" element={<PrizeRules />} />
            <Route path="drc-wrap-up" element={<DrcWrapUp />} />
            <Route path="inaugural-drc" element={<InauguralDrc />} />
            <Route path="competition-format" element={<CompetitionFormat />} />
            <Route path="map" element={<Map />} />

            {/* DRC competitions pages */}
            <Route path="2024" element={<TwentyTwentyFour />} />
            <Route path="2023" element={<TwentyTwentyThree />} />
            <Route path="2022" element={<TwentyTwentytwo />} />
            <Route path="2021" element={<TwentyTwentyone />} />
            <Route path="2020" element={<TwentyTwenty />} />
            <Route path="2019" element={<TwentyNineteen />} />
            <Route path="2018" element={<TwentyEighteen />} />
            <Route path="2017" element={<TwentySeventeen />} />
            <Route path="2016" element={<TwentySixteen />} />
          </Route>

          {/* Resource pages */}
          <Route path="resources">
            <Route index element={<Resources />} />

            {/* Workshop pages */}
            <Route path="workshops">
              <Route path="robot-arm" element={<RobotArmWorkshop />} />
              <Route path="workbot" element={<WorkBots />} />
              <Route path="suppliers" element={<Suppliers />} />
              <Route path="robotic-systems" element={<RoboticsSystems />} />
              <Route path="soldering" element={<Soldering />} />
              <Route
                path="3d-modelling-with-cad"
                element={<ModellingWithCad />}
              />
              <Route
                path="robot-power-systems"
                element={<RobotPowerSystems />}
              />
              <Route
                path="programming-microcontrollers-in-c"
                element={<ProgrammingMicrocontrollers />}
              />
            </Route>
          </Route>

          {/* Redirect routes */}
          <Route path="/robots" element={<Navigate to='/projects' />} />
          <Route path="/workshops" element={<Navigate to='/resources' />} />
          <Route path="/droid-racing-challenge" element={<Navigate to='/drc'/>} />
          <Route path="/droid-racing-challenge/drc2016" element={<Navigate to='/drc/2016'/>} />
          <Route path="/droid-racing-challenge/drc2017" element={<Navigate to='/drc/2017'/>} />
          <Route path="/droid-racing-challenge/drc2019" element={<Navigate to='/drc/2019'/>} />
          <Route path="/droid-racing-challenge-2020" element={<Navigate to='/drc/2020'/>} />
          <Route path="/droid-racing-challenge-2021" element={<Navigate to='/drc/2021'/>} />
          <Route path="/droid-racing-challenge-2022" element={<Navigate to='/drc/2022'/>} />
          <Route path="/droid-racing-challenge-2023" element={<Navigate to='/drc/2023'/>} />
          <Route path="/armageddonr1/" element={<Navigate to="/projects/armageddon" />} />
          <Route path="/armageddonr2/" element={<Navigate to="/projects/armageddon" />} />
          <Route path="/page/2" element={<Navigate to="" />} />
          {/*<Route path="/droid-racing-challenge/drc2016/competition-format/" element={<Navigate to="/" />} />*/}


          {/*Error routes */}
          <Route path="*" element={<NotFound />} />
        </Routes>

        <div id="footer-div" />
        <Footer />
    </div>
  );
}

export default App;
