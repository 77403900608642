import { getProjects } from "../db/data";
export default function getMenuItems() {
    const projs = getProjects()
    // add project names from projs to the object in menuItems with title Projects
    menuItems[2].subMenu = []
    return projs.then(projects => {
        projects.forEach(project => {
            console.log(project)
            menuItems[2].subMenu.push({
                title: project.title,
                url: `/projects/${project.slug}`
            })
        })
        return menuItems
    })

}

var menuItems = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Team',
      url: '/team',
    },
    {
      title: 'Projects',
      url: '/projects',
    },
    {
    title: 'Events',
    url: '/events',
    },
    {
    title: 'DRC',
    url: '/drc',
    subMenu: [
        {
            title: "2024",
            url: '/drc/2024'
        },
        {
            title: "2023",
            url: '/drc/2023'
        },
        {
            title: "2022",
            url: '/drc/2022'
        },
        {
            title: "Rules",
            url: '/drc/rules'
        },
        {
            title: "FAQ",
            url: '/drc/faqs'
        },
        {
            title: "MATLAB Adoption Prize",
            url: '/drc/mathworks-resources'
        }
    ]
    },
    {
    title: 'Sponsors',
    url: '/sponsors',
    },
    {
    title: 'Resources',
    url: '/resources',
    },
    {
    title: 'Contact Us',
    url: '/contact',
    },
  ];