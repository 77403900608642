import React from "react";
import QUTRC_Logo from "../images/qutrc_logo.png";
import Linkedin from "../images/external logos/linkedin.svg";
import Facebook from "../images/external logos/facebook.svg";
import Linktree from "../images/external logos/linktree.svg";
import Tumblr from "../images/external logos/tumblr-logo.png";
import "../css/footer.css";

const Footer = () => (
  <footer>
    <a href="/developers" style={{height: "50px"}}>
      <img className="brand-icon" src={QUTRC_Logo} alt="QUTRC Logo" />
    </a>

    <p className="footer-text">Copyright 2024</p>

      <div id="footer-links">
          <a className="footer-button" href="https://qutroboticsclub.getqpay.com/">Membership!</a>
          <a href="https://qutroboticsclub.tumblr.com/" target="_blank" rel="noreferrer" style={{height: "50px", width: "50px"}}>
				    <img src={Tumblr} alt="Tumblr" height="100" className="img"/>
			    </a>
          <a className="footer-button" href="https://qutroboticsmerch.getqpay.com/">Our Merch!</a>
      </div>

    <ul className="footer-socials">
      <li>
        <a href="https://qutroboticsclub.tumblr.com/" target="_blank" rel="noreferrer" style={{height: "50px", width: "50px"}}>
          <img src={Tumblr} alt="Tumblr" height="100" className="img"/>
        </a>
      </li>
      
      <li>
        <a href="https://www.linkedin.com/company/qut-robotics-club" target="__blank">
          <img src={Linkedin} alt="Linkedin" width="50px" height="50px" />
        </a>
      </li>

      <li>
        <a href="https://www.facebook.com/QUTRoboticsClub"target="__blank">
          <img src={Facebook} alt="Facebook" width="50px" height="50px" />
        </a>
      </li>

      <li>
        <a href="https://linktr.ee/qutroboticsclub" target="__blank">
          <img src={Linktree} alt="Linktree" width="50px" height="50px" />
        </a>
      </li>
    </ul>

  </footer>
);

export default Footer;
