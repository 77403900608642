import React from "react";
import QCR from "../images/sponsors/qcr.svg";
import Boeing from "../images/sponsors/boeing.svg";

const Sponsors = () => {
	return (
		<div className="Sponsor">
			{/* <image src={SponsorsList} alt="Sponsors List" width="100%" height="100%" /> */}
			<h1>Our Sponsors</h1>
			<a href="https://research.qut.edu.au/qcr/" target="_blank" rel="noreferrer">
				<img src={QCR} alt="QCR" height="100" className="img"/>
			</a>

			<a href="https://www.boeing.com.au/" target="_blank" rel="noreferrer">
				<img src={Boeing} alt="Boeing" height="100" className="img"/>
			</a>

		</div >
	);
}

export default Sponsors;