import React from "react";
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";


import image1 from "../images/gallery photos/1.jpg"
import image2 from "../images/gallery photos/2.jpg"
import image3 from "../images/gallery photos/3.jpg"
import image4 from "../images/gallery photos/4.jpg"
import image5 from "../images/gallery photos/5.jpg"
import image6 from "../images/gallery photos/6.jpg"
import image7 from "../images/gallery photos/7.jpg"
import image8 from "../images/gallery photos/8.jpg"
import image9 from "../images/gallery photos/9.jpg"
import image10 from "../images/gallery photos/10.jpg"
import image11 from "../images/gallery photos/11.jpg"
import image12 from "../images/gallery photos/12.jpg"
import image13 from "../images/gallery photos/13.jpg"
import image14 from "../images/gallery photos/14.jpg"
import image15 from "../images/gallery photos/15.jpg"


const images = [
    {
        original: image12
    },
    {
        original: image7
    },
    {
        original: image3
    },
    {
        original: image1
    },
    {
        original: image5
    }, 
    {
        original: image6
    }, 
    {
        original: image4
    }, 
    {
        original: image8
    }, 
    {
        original: image9
    }, 
    {
        original: image10
    }, 
    {
        original: image11
    }, 
    {
        original: image2
    }, 
    {
        original: image13
    },
    {
        original: image14
    },
    {
        original: image15
    }
]


const Gallery = () => {
    return (
        <div className="gallery-div">
            <div className="gallery-container">
                <h1>Gallery</h1>
                <ImageGallery
                    items={images}
                    showThumbnails={false}
                    showBullets={true}
                />
            </div>
        </div>
    );
}

export default Gallery