import React from "react";
import Values from "../images/elements/values.svg";
import ARCHP from "../images/elements/rover.svg";
import Sponsors from "../components/sponsors"
import Gallery from "../components/gallery";

const Home = () => {
	return (
		<div className="home">
			<div className="home-content">
				<img src={ARCHP} alt="Landing" className="home-values" width="100%" height="80%" />
			</div>

			<div className="home-main">
				<div className="home-container">

					<div>
						<h1>About Us</h1>
						<div className="home-text">
							<p>The QUT Robotics Club is a student led club, dedicated to promoting and fostering the learning of robotics within the QUT community. Our club is held together by a community of members who’s bonds stretches on after university, across subjects and into the workforce.</p>

							<p>Our workshops and projects help both further the education of our members and strengthen their relationships with one another. We help with the development of robotic, software, mechanical and electrical skills, as well as provide many industry and networking opportunities, resulting in our member’s being uniquely equipped for industry work when they graduate.</p>
						</div>
					</div>

					<span></span>
					<div className="home-image">
						<img src={Values} alt="QUTRC Logo" width="85%" height="85%" />
					</div>

					<Gallery />

					<Sponsors />

					<div>
						<p>
							<br/>
							ABN: 34 411 341 964
							<br/>
							Reachout to us via our <a id="rules" href="/contact"> contact form
        					</a> or through social media.
							<br/>
							Address: 920 S Block, QUT Gardens Point Campus, 2 George St, Brisbane City QLD 4000
						</p>
					</div>
				</div>
			</div>

		</div>

	);
}

export default Home;